import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/ambassadeur',
            name: 'Ambassador',
            component: () =>
            {
                return import( /* webpackChunkName: "ambassador" */ '@/app/pages/Ambassador' );
            }
        },
        {
            path: '/rapporteer',
            name: 'Report',
            component: () =>
            {
                return import( /* webpackChunkName: "report" */ '@/app/pages/Report' );
            }
        },
        {
            path: '/:region?',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
