import Proxy from './Proxy';

class UserProxy extends Proxy
{
    constructor( parameters = {})
    {
        super( 'api/users', parameters );
    }

    me()
    {
        return this.submit( 'get', 'api/users/me' );
    }

    request( data )
    {
        return this.submit( 'post', 'api/users/request', data );
    }

    report( data )
    {
        return this.submit( 'post', 'api/users/report', data );
    }
}

export default UserProxy;
