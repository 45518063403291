import RegionProxy from '@/app/proxies/RegionProxy';

export default {
    namespaced: true,

    state: {
        regions: [],
        selectedName: 'utrecht'
    },

    getters: {
        get: state =>
        {
            return state.regions;
        },

        selectedRegion: state =>
        {
            return state.regions.filter( item => item.name.toLowerCase() === state.selectedName )[0];
        },

        selectedRegionName: state =>
        {
            return state.selectedName;
        }
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            let transformed = payload.map( region =>
            {
                region.area.coordinates[0] = region.area.coordinates[0].map( coord =>
                {
                    return { lat: coord[1], lng: coord[0] };
                });
                region.center_point.coordinates = { lat: region.center_point.coordinates[1], lng: region.center_point.coordinates[0] };
                return region;
            });
            state.regions = transformed;
        },

        selectRegion: ( state, payload ) =>
        {
            state.selectedName = payload;
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        selectNew: ( context, payload ) =>
        {
            context.commit( 'selectRegion', payload );
        },

        getJson: ( context ) =>
        {
            return new RegionProxy()
                .all()
                .then( response =>
                {
                    context.commit( 'assign', response );
                })
                .catch( error =>
                {
                    return error;
                });
        }
    }
};
