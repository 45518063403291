import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

import VeeValidate from 'vee-validate';
import dict from 'vee-validate/dist/locale/nl';
import { i18n } from '@/app/config';
import * as VueGoogleMaps from 'vue2-google-maps';

VuexRouterSync.sync( store, router );

Vue.use( VeeValidate, {
    events: 'change'
});

VeeValidate.Validator.localize( 'nl', Object.assign( dict, i18n.nl ) );

Vue.use( VueGoogleMaps, {
    load: {
      key: 'AIzaSyAkQ2iEw_lAPDdaEz4mQ4CAoja8YNJnqlI',
      libraries: 'places'
    }
});

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

store.dispatch( 'regions/getJson' );
store.dispatch( 'user/apiToken', window._rootData.user );

// require( 'c3/c3.css' );

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
