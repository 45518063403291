<template>
    <div class="main-layout">
        <header>
            <router-link to="/" class="title button"><img src="/static/img/luchtpunt.png" alt="Luchtpunt.nl" class="logo" /></router-link>
            <nav>
                <router-link to="/ambassadeur" v-if="!user" class="button">Ambassadeur worden</router-link>
                <router-link to="/rapporteer" v-if="user" class="button">Punten doorgeven</router-link>
            </nav>
        </header>
        <GmapMap
            ref="gmap"
            :center="{lat:52.1, lng:5.3}"
            :zoom="8"
            map-type-id="terrain">
        </GmapMap>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

        computed: {
            user()
            {
                return this.$store.getters['user/me'];
            },
            regions()
            {
                return this.$store.getters['regions/get'];
            },

            selectedRegion()
            {
                return this.$store.getters['regions/selectedRegion'];
            },

            selectedRegionName()
            {
                return this.$store.getters['regions/selectedRegionName'];
            }
        },

        watch: {
            'regions': function( pVal )
            {
                this.setMapPolygons();
            },
            'selectedRegion': function( pVal )
            {
                this.onResize();
            }
        },
        mounted()
        {
            this.polygons = [];
            if( this.regions.length > 0 )
            {
                this.setMapPolygons();
            }
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                if( this.polygons.length === 0 ) return;
                this.$refs.gmap.$mapPromise.then( map =>
                {
                    map.fitBounds( this.bounds, -2.5 );
                });
            },
            setMapPolygons()
            {
                this.polygons = [];
                this.bounds = new window.google.maps.LatLngBounds();
                this.$refs.gmap.$mapPromise.then( map =>
                {
                    map.setOptions({
                        disableDefaultUI: true
                    });
                    for( let region of this.regions )
                    {
                        let a = new window.google.maps.Polygon({
                            fillColor: '#41ade2',
                            fillOpacity: region.name.toLowerCase() === this.selectedRegionName ? 0.75 : 0.5,
                            strokeWeight: 1,
                            strokeOpacity: 0.3
                        });
                        a.setPath( region.area.coordinates[0] );
                        for( let i in region.area.coordinates[0] )
                        {
                            this.bounds.extend( region.area.coordinates[0][i] );
                        }
                        a.setMap( map );
                        a.region = region;

                        a.addListener( 'click', ( event ) =>
                        {
                            this.$router.push( a.region.name.toLowerCase() );
                        });

                        a.addListener( 'mouseover', ( event ) =>
                        {
                            this.polygons.forEach( item =>
                            {
                                item.setOptions({ fillOpacity: 0.5 });
                            });
                            a.setOptions({ fillOpacity: 0.75 });
                        });

                        a.addListener( 'mouseout', ( event ) =>
                        {
                            this.polygons.forEach( item =>
                            {
                                item.setOptions({ fillOpacity: item.region.name.toLowerCase() === this.selectedRegionName ? 0.75 : 0.5 });
                            });
                        });

                        this.polygons.push( a );

                        let icon = new window.google.maps.Marker({
                            clickable: false,
                            position: region.center_point.coordinates,
                            map: map,
                            animation: window.google.maps.Animation.DROP,
                            icon: {
                                url: '/static/img/marker.png',
                                scaledSize: { width: 32, height: 32 }
                            }
                        });
                        icon.getCursor();
                        console.log( region.today.rating );
                        let number = new window.google.maps.Marker({
                            clickable: false,
                            position: region.center_point.coordinates,
                            map: map,
                            icon: {
                                anchor: new window.google.maps.Point( 89 / 2, -8 ),
                                url: '/static/img/number' + String( region.today.rating ).split( '.' )[0] + '.png',
                                scaledSize: { width: 89 / 2, height: 55 / 2 }
                            }
                        });
                        number.getCursor();

                        let sub = String( region.today.rating ).split( '.' )[1];
                        if( !sub )
                        {
                            sub = '0';
                        }
                        let number2 = new window.google.maps.Marker({
                            clickable: false,
                            position: region.center_point.coordinates,
                            map: map,
                            icon: {
                                anchor: new window.google.maps.Point( 0, -8 ),
                                url: '/static/img/number.' + sub + '.png',
                                scaledSize: { width: 89 / 2, height: 55 / 2 }
                            }
                        });
                        number2.getCursor();
                    }
                    map.fitBounds( this.bounds, -2.5 );
                });
            }
        }
    };
</script>
