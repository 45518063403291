import UserProxy from '@/app/proxies/UserProxy';

export default {
    namespaced: true,

    state: {
        me: null,
        apiToken: null,
        requested: false
    },

    getters: {
        requested: state =>
        {
            return state.requested;
        },
        me: state =>
        {
            return state.me;
        },

        apiToken: state =>
        {
            return state.apiToken;
        },

        reports: state =>
        {
            return state.me ? state.me.reports : [];
        },

        todayReport: state =>
        {
            let reps = state.me ? state.me.reports : [];
            if( reps.length < 1 ) return null;
            let lastNight = new Date();
            lastNight.setHours( 0 );
            lastNight.setMinutes( 0 );
            lastNight.setSeconds( 0 );
            lastNight.setMilliseconds( 0 );
            console.log( reps[0].created_at, new Date( reps[0].created_at ).getTime(), lastNight.getTime() );
            if( new Date( reps[0].created_at ) >= lastNight )
            {
                return reps[0];
            }
            return null;
        }
    },

    mutations: {
        me: ( state, payload ) =>
        {
            state.me = payload;
        },
        requested: ( state, payload ) =>
        {
            state.requested = payload;
        },

        apiToken: ( state, payload ) =>
        {
            state.apiToken = payload;
        }
    },

    actions: {
        fetchUser: ( context ) =>
        {
            return new UserProxy({ api_token: context.getters.apiToken })
                .me()
                .then( response =>
                {
                    console.log( response );
                    context.commit( 'me', response );
                })
                .catch( error =>
                {
                    console.log( 'error!', error );
                    return error;
                });
        },

        apiToken: ( context, payload ) =>
        {
            console.log( 'issie?', payload );
            context.commit( 'apiToken', payload );
            context.dispatch( 'fetchUser' );
        },

        request: ( context, payload ) =>
        {
            return new UserProxy()
                .request( payload )
                .then( response =>
                {
                    console.log( response );
                    context.commit( 'requested', true );
                })
                .catch( error =>
                {
                    console.log( 'error!', error );
                    return error;
                });
        },

        report: ( context, payload ) =>
        {
            return new UserProxy({ api_token: context.getters.apiToken })
                .report( payload )
                .then( response =>
                {
                    console.log( response );
                    context.dispatch( 'fetchUser' );
                })
                .catch( error =>
                {
                    console.log( 'error!', error );
                    return error;
                });
        }
    }
};
