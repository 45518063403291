import Proxy from './Proxy';

class RegionProxy extends Proxy
{
    constructor( parameters = {})
    {
        super( 'api/places', parameters );
    }
}

export default RegionProxy;
